// import PropTypes from "prop-types";
import React from "react";
import { navigate, Link } from "gatsby";
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import querystring from 'querystring';
import moment from 'moment';
import { Button, Tooltip, Icon, Select, Form, Input, Spin, DatePicker, AutoComplete, Modal, notification } from 'antd';
import Seo from "../components/Seo";

import { ThemeContext } from "../layouts";
import Article from "../components/Article";
import Headline from "../components/Article/Headline";

import { getFirebase } from '../utils/helpers'
import qr from "../images/png/bld-venmo-qr.png";


const { RangePicker } = DatePicker;
const { Option } = Select;

const labelToOptions = l => ({ value: l, label: l });

const districtOptions = [
  'Albany',
  'Allentown',
  'Cincinnati',
  'Detroit',
  'Edmonton',
  'Long Island',
  'Los Angeles',
  'Newark',
  'Phoenix',
  'Reno',
  'Rockland',
  'San Francisco Bay Area',
  'Seattle',
  'Toronto',
  'Trenton',
  'Vancouver',
  'Washington DC',
];

const tshirtOptions = [ 'Small', 'Medium', 'Large', 'XL', 'XXL' ].map(labelToOptions);
const typeOptions = [
  { value: 'participant', label: 'Participant (Singles & Mingles)' },
  { value: 'auxie', label: 'Non-Participant (Adult Auxies)' },
];

let uiConfig = null;
let firebase = null;

function loadUIConfig (firebase) {
  return {
    // Will use popup for IDP Providers sign-in flow instead of the default, redirect.
    // signInFlow: 'popup',
    signInOptions: [
      // Leave the lines as is for the providers you want to offer your users.
      firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    ],
    callbacks: {
      // Avoid redirects after sign-in.
      signInSuccessWithAuthResult: () => false
    }
  };
}

const iconStyles = (extra = {}) => ({ fontSize: '24px', height: '24px', marginRight: '12px', ...extra })
const Status = props => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '70px' }}>

    {props.updating && <Spin size="large" />}
    {props.registered.name && (
      <div>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: '18px'}}>
          <Icon type="check-circle-o" style={iconStyles({color: 'green'})} />
          Registered
        </div>
        <div style={{display: 'flex', alignItems: 'center', marginBottom: '18px'}}>
          {!!props.registered.paid
            ? <React.Fragment><Icon type="check-circle-o" style={iconStyles({color: 'green'})} />
                {`Paid ${props.registered.paid === 'venmoUnconfirmed'
                  ? ' - Venmo (Unconfirmed: pending Ray Urbino\'s confirmation)'
                  : props.registered.paid === 'venmoConfirmed'
                    ? '- Venmo'
                    : ''
                }`}
              </React.Fragment>
            : <React.Fragment><Icon type="minus-circle-o" style={iconStyles()} />Not Paid</React.Fragment>
          }
        </div>
      </div>
    )}
  </div>
);

const Counter = props => (
  <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly' }}>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ fontSize: '63px', marginRight: '7px' }}>{props.count}</div>
      <div style={{ fontSize: '17px', fontWeight: 'bold' }}>TOTAL<br />REGISTRANTS</div>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <div style={{ fontSize: '63px', marginRight: '7px' }}>{props.districts}</div>
      <div style={{ fontSize: '17px', fontWeight: 'bold' }}>{`DISTRICT${props.districts > 1 ? 'S' : ''}`}<br />REPRESENTED</div>
    </div>
  </div>
);

class RegistrationPage extends React.Component {
  constructor (props) {
    super(props);

    this.state = {
      user: null,
      loginReady: false,
      registered: {},
      updating: false,
      payModal: false,
    }
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handlePayClick = this.handlePayClick.bind(this);
    this.handleUpdateAndPay = this.handleUpdateAndPay.bind(this);
    this.handleVenmoClick = this.handleVenmoClick.bind(this);
  }

  openNotificationWithIcon = () => {
    if (!this.state.registered.name) {
      notification['success']({
        message: 'Thank You',
        description: 'You have successfully registered for SIC 2019!',
      });
    } else {
      notification['success']({
        message: 'Updated',
        description: 'You have successfully updated your registration details.',
      });
    }
  };

  handlePayRedirect () {
    if (this.props.location.search.length > 90) {
      const queryData = querystring.parse(this.props.location.search.substr(1));
      const { checkoutId, referenceId, transactionId } = queryData;

      if (transactionId) {
        firebase.firestore().collection('users').doc(this.state.user.uid).set({
          paymentInfo: { checkoutId, referenceId, transactionId },
          paid: true,
          paidOn: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true }).then(() => {
          navigate('/register/');
        });
      }
    }
  }

  componentDidMount () {
    const app = import('firebase/app');
    const auth = import('firebase/auth');
    const firestore = import('firebase/firestore');

    Promise.all([app, auth, firestore]).then(([fbase]) => {
      firebase = getFirebase(fbase);
      uiConfig = loadUIConfig(firebase);

      this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
        if (!user) return this.setState({ loginReady: true });
        const { displayName, email, photoURL, uid } = user;

        // check if user is regstered
        firebase.firestore().collection('users').doc(uid).get().then(doc => {
          if (doc.exists) {
            this.setState({ registered: doc.data() });
          }
        });
        // save for when we have > 100 registrants
        // firebase.firestore().collection('count').get().then(docs => {
        //     docs.forEach(doc => {
        //       this.setState({ countData: doc.data() });
        //     });
        // });

        this.setState({
          user: { displayName, email, photoURL, uid },
        }, this.handlePayRedirect);
      });
    });
  }

  componentWillUnmount () {
    this.unregisterAuthObserver();
  }

  handleSignOut () {
    this.setState({ user: null }, _ => firebase.auth().signOut())
  }

  handlePayClick = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let type = '';
        if (this.props.form.getFieldValue('type') === 'auxie') {
          type = '&type=auxie';
        }
        window.location.assign(`/.netlify/functions/pay?email=${values.email}&name=${values.name}${type}`);
      }
    });
  }

  handleVenmoClick = e => {
    e.preventDefault();
    firebase.firestore().collection('users').doc(this.state.user.uid).set({
      paid: 'venmoUnconfirmed',
      paidOn: firebase.firestore.FieldValue.serverTimestamp(),
    }, { merge: true })
      .then(() => {
        this.setState({ payModal: false, registered: { ...this.state.registered, paid: 'venmoUnconfirmed' } })
      })
      .catch(e => console.log('error', e));
  }

  handleUpdateAndPay = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ updating: true });
        firebase.firestore().collection('users').doc(this.state.user.uid).set({
          district: values.district,
          email: values.email,
          name: values.name,
          nickname: values.nickname || '',
          photo: this.state.user.photoURL || '',
          phone: values.phone,
          stayStart: values.stayRange && values.stayRange[0] ? values.stayRange[0].format('LLLL') : '',
          stayEnd: values.stayRange && values.stayRange[1] ? values.stayRange[1].format('LLLL') : '',
          allergies: values.allergies || '',
          tshirt: values.tshirt,
          type: values.type || 'participant',
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true })
          .then(() => this.setState({ payModal: true, updating: false }, this.openNotificationWithIcon))
          .catch(e => console.log('error', e))
      }
    });
  }

  handleUpdate = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        this.setState({ updating: true })
        firebase.firestore().collection('users').doc(this.state.user.uid).set({
          district: values.district,
          email: values.email,
          name: values.name,
          nickname: values.nickname || '',
          photo: this.state.user.photoURL || '',
          phone: values.phone,
          stayStart: values.stayRange && values.stayRange[0] ? values.stayRange[0].format('LLLL') : '',
          stayEnd: values.stayRange && values.stayRange[1] ? values.stayRange[1].format('LLLL') : '',
          allergies: values.allergies || '',
          tshirt: values.tshirt,
          type: values.type || 'participant',
          lastUpdated: firebase.firestore.FieldValue.serverTimestamp(),
        }, { merge: true })
        .then(() => this.setState({ updating: false }, this.openNotificationWithIcon))
        .catch(e => console.log('error', e))
      }
    });
  }

  handleConfirmBlur = e => {
    const value = e.target.value;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  }

  render () {
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: { xs: { span: 24 }, sm: { span: 8 } },
      wrapperCol: { xs: { span: 24 }, sm: { span: 16 } },
    };

    const tailFormItemLayout = {
      wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 16, offset: 8 } },
    };

    return (
      <React.Fragment>
        <ThemeContext.Consumer>
          {theme => (
            <Article theme={theme}>
              <header>
                <Headline title="Registration" theme={theme} />
              </header>
              {!this.state.user
                ? this.state.loginReady
                  ? (
                    <React.Fragment>
                      <p>Please sign in to register and see your registration status.</p>
                      <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
                    </React.Fragment>
                  )
                  : <div> Loading ... </div>
                : (
                  <React.Fragment>
                    <Form onSubmit={this.handleUpdate}>
                    <Form.Item {...formItemLayout} label='Full Name'>
                        {getFieldDecorator('name', {
                          initialValue: this.state.registered.name || this.state.user.displayName,
                          rules: [{ required: true, message: 'Please input your nickname', whitespace: true }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label={(
                          <span>
                            Nickname&nbsp;
                            <Tooltip title="What do you want others to call you?">
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                        )}
                      >
                        {getFieldDecorator('nickname', {
                          initialValue: this.state.registered.nickname || '',
                          rules: [{ required: false, message: 'Please input your nickname', whitespace: true }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                      <Form.Item {...formItemLayout} label="E-mail">
                        {getFieldDecorator('email', {
                          initialValue: this.state.registered.email || this.state.user.email,
                          rules: [
                            { type: 'email', message: 'Please enter a valid email' },
                            { required: true, message: 'Please input an email address to contact you' }
                          ],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                      <Form.Item {...formItemLayout} label="Phone Number">
                        {getFieldDecorator('phone', {
                          initialValue: this.state.registered.phone || '',
                          rules: [{ required: true, message: 'Please enter a number to reach you' }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                      <Form.Item {...formItemLayout} label="District">
                        {getFieldDecorator('district', {
                          initialValue: this.state.registered.district || '',
                          rules: [{ required: true, message: 'Please select your BLD district' }],
                        })(
                          // <Select>
                          //   {districtOptions.map(o => <Option key={o.value} value={o.value}>{o.label}</Option>)}
                          // </Select>
                          <AutoComplete
                            dataSource={districtOptions}
                            placeholder="Please enter your BLD district"
                            filterOption={(inputValue, option) => option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                          />
                        )}
                      </Form.Item>
                      {this.props.form.getFieldValue('district') === 'San Francisco Bay Area'
                        ? (
                          <Form.Item {...formItemLayout} label="Type">
                          {getFieldDecorator('type', {
                            initialValue: this.state.registered.type || 'participant',
                            rules: [{ required: true, message: 'Please let us know how you will be attending as' }],
                          })(
                            <Select disabled={!!this.state.registered.type}>
                              {typeOptions.map(o => <Option key={o.value} value={o.value}>{o.label}</Option>)}
                            </Select>
                          )}
                        </Form.Item>
                        ) : (
                          <Form.Item {...formItemLayout} label="Arrival & Departure Dates">
                          {getFieldDecorator('stayRange', {
                            rules: [{ type: 'array', required: false, message: 'Please select time!' }],
                            initialValue: [
                              this.state.registered.stayStart
                                ? moment(this.state.registered.stayStart, 'LLLL')
                                : moment('2019-08-01', 'YYYY-MM-DD'),
                              this.state.registered.stayEnd
                                ? moment(this.state.registered.stayEnd, 'LLLL')
                                : moment('2019-08-05', 'YYYY-MM-DD'),
                            ]
                          })(
                            <RangePicker format="YYYY-MM-DD" />
                          )}
                        </Form.Item>
                        )
                      }
                      <Form.Item {...formItemLayout} label="T-Shirt Size">
                        {getFieldDecorator('tshirt', {
                          initialValue: this.state.registered.tshirt || 'Medium',
                          rules: [{ required: true, message: 'Please let us know your t-shirt size' }],
                        })(
                          <Select>
                            {tshirtOptions.map(o => <Option key={o.value} value={o.value}>{o.label}</Option>)}
                          </Select>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...formItemLayout}
                        label={(
                          <span>
                            Allergies&nbsp;
                            <Tooltip title="Are you allergic to anything?  (i.e. any food or materials)">
                              <Icon type="question-circle-o" />
                            </Tooltip>
                          </span>
                        )}
                      >
                        {getFieldDecorator('allergies', {
                          initialValue: this.state.registered.allergies || '',
                          rules: [{ required: false, message: 'Please let us know any allergies you have', whitespace: true }],
                        })(
                          <Input />
                        )}
                      </Form.Item>
                      {/* <Form.Item {...tailFormItemLayout}>
                        {getFieldDecorator('agreement', {
                          valuePropName: 'checked',
                        })(
                          <Checkbox>I have read the <a href="">agreement</a></Checkbox>
                        )}
                      </Form.Item> */}
                      <Form.Item {...tailFormItemLayout}>
                        <Button type="default" htmlType="submit" disabled={this.state.updating}>
                          {this.state.registered.name ? 'Update' : 'Register'}
                        </Button>
                        &nbsp;
                        {!this.state.registered.paid && <Button
                          type="primary"
                          onClick={this.handleUpdateAndPay}
                          disabled={this.state.updating || this.state.registered.paid}>
                          {this.state.registered.name ? 'Update & Pay' : 'Register & Pay'}
                        </Button>}
                      </Form.Item>
                    </Form>

                    <Status updating={this.state.updating} registered={this.state.registered} />
                    <br /><hr /><br />
                    <h3>Registration Fee</h3><br />
                    <p style={{ fontSize: '13px'}}>
                    The $80 registration fee for participants covers our use of the venue, meals, and a Bay SIC swag pack. This registration fee does not include transportation and lodging, so be sure to read our post on <Link style={{ color: '#6b191f' }} to='/gettinghere'>flights and hotels</Link>! Also, if you pay using credit card, there is a surcharge of $2.70 to cover Square processing fee.
                    </p>
                    {this.state.countData && <React.Fragment><br /><hr /><br /></React.Fragment>}
                    {this.state.countData && <Counter count={this.state.countData.count} districts={Object.keys(this.state.countData).length - 1} />}

                    <Modal
                      visible={this.state.payModal}
                      title="Payment Methods"
                      onCancel={() => this.setState({ payModal: false })}
                      footer={null}
                      width={700}
                      centered
                    >
                      <p>There are two ways to pay:</p>
                      <div style={{ display: 'flex', alignItems: 'stretch', flexWrap: 'wrap', justifyContent: 'space-around' }}>
                        <div style={{ borderRadius: '4px', border: '1px solid grey', padding: '10px', maxWidth: '300px', marginTop: '20px' }}>
                          <h3>Venmo</h3><br />
                          <p>Price: <b>{this.props.form.getFieldValue('type') === 'auxie' ? '$40' : '$80'}</b><br />
                          Please send {this.props.form.getFieldValue('type') === 'auxie' ? '$40' : '$80'} to <a style={{ color: '#6b191f', fontWeight: 'bold' }} href="https://venmo.com/code?user_id=1769775172681728112">@Ray-Urbino</a> on Venmo with your name, BLD district and "SIC 2019".</p>
                          <div style={{ textAlign: 'center' }}><img src={qr} style={{ width: '150px' }} /></div>
                          <div style={{ textAlign: 'center' }}>
                            <Button key="venmo" onClick={this.handleVenmoClick}>Finished Venmo Payment</Button>
                          </div>
                        </div>
                        <div style={{
                          borderRadius: '4px',
                          border: '1px solid grey',
                          padding: '10px', position: 'relative', maxWidth: '300px', marginTop: '20px', minHeight: '185px' }}>
                          <h3>Credit Card</h3><br />
                          <p>Price: <b>{this.props.form.getFieldValue('type') === 'auxie' ? '$41.50' : '$82.70'}</b><br />Clicking below will redirect you to a checkout page where you can finish paying with credit card.</p>
                          <div style={{ textAlign: 'center', position: 'absolute', bottom: '10px', width: '100%', marginLeft: '-10px' }}>
                            <Button key="pay" type="primary" loading={this.state.updating} onClick={this.handlePayClick}>
                              Online (Credit Card)
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </React.Fragment>
                )
              }
              {/* --- STYLES --- */}
              <style jsx>{`
                .form {
                  background: transparent;
                }

                :global(.ant-input:hover) {
                  border-color: ${theme.color.brand.primary};
                }
                :global(.ant-input:focus) {
                  border-color: ${theme.color.brand.primary};
                  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
                }

                :global(.ant-btn-primary) {
                  background: ${theme.color.brand.primary};
                  border: 1px solid ${theme.color.brand.primary};
                }
                :global(.ant-btn-primary:hover) {
                  background: ${theme.color.brand.primary};
                  border: 1px solid ${theme.color.brand.primary};
                }

                @from-width desktop {
                  .form :global(input) {
                    max-width: 50%;
                  }
                }
              `}</style>
            </Article>
          )}
        </ThemeContext.Consumer>
        <Seo />
      </React.Fragment>
    );
  }
}

const WrappedRegistrationPage = Form.create({ name: 'register' })(RegistrationPage);

// RegistrationPage.propTypes = {
//   data: PropTypes.object.isRequired
// };

export default WrappedRegistrationPage;
